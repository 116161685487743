import Vue from 'vue';
import App from '../shared/App.vue';
import langs from '../../translations.json';
import router from './router';
import AppConfig from './config';
import '../shared/styles.scss';
// import authentication from "./authentication"
import Keycloak from 'keycloak-js';

AppConfig.baseApi = 'https://purchase-history.link/api/v1';

Vue.config.productionTip = false;
Vue.prototype.trans = (str) => {
  const result = langs[AppConfig.lang][str];
  if (result) {
    return result;
  } else {
    return 'Invalid translation for ' + str + ' in language ' + AppConfig.lang;
  }
};

const options = {
  url: AppConfig.authApi,
  realm: 'purchase_history',
  clientId: 'front',
};

const keycloak = Keycloak(options);
Vue.prototype.$keycloak = keycloak;
Vue.prototype.$locationEncoded = encodeURI(window.location.origin);

keycloak
  .init({ onLoad: 'login-required', checkLoginIframe: false })
  .then(() => {
    new Vue({
      router,
      render: (h) => h(App),
    }).$mount('#app');
  });
