import Vue from 'vue';
import Router from 'vue-router';
import ECSiteList from '../shared/pages/ECSiteList.vue';
import ECSiteLogin from '../shared/pages/ECSiteLogin.vue';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'EC Site Settings',
      component: ECSiteList,
    },
    {
      path: '/:siteId',
      name: 'EC Site Login',
      component: ECSiteLogin,
    },
  ],
});

router.beforeEach((to, from, next) => {
  // Get the actual url of the app, it's needed for Keycloak
  const basePath = window.location.toString();
  const keycloak = Vue.prototype.$keycloak;
  if (!keycloak.authenticated) {
    // The page is protected and the user is not authenticated. Force a login.
    keycloak.login({ redirectUri: basePath.slice(0, -1) + to.path });
  } else {
    // The user was authenticated, and has the app role
    keycloak.updateToken(70)
      .then(() => {
        next();
      })
      .catch((err) => {
        console.error(err);
      });
  }
});

export default router;
