













































import { Component, Vue } from 'vue-property-decorator';
import ApiService from '../services/ApiService';

@Component({
  components: {},
})
export default class ECSiteList extends Vue {
  public ecSiteSettings = {
    title: Vue.prototype.trans('title'),
    description: '',
  };
  public userLoadErrorMsg = null;
  public sites = null;

  /**
   * check is failed
   * @param site the site entity
   */
  public isFailed(site: any) {
    return !(
      site.authStatus && site.authStatus.toLowerCase().indexOf('success') >= 0
    );
  }

  /**
   * when checkbox changed
   * @param e the event
   * @param site the site id
   */
  public ecUseFlagChange(e: any, site: any) {
    const userId = this.$route.params.id;
    ApiService.updateECSite(userId, site.id, { ecUseFlag: e.target.checked })
      .then(() => {
        console.log('update successful');
      })
      .catch((err) => {
        console.error(err);
      });
  }

  /**
   * goto login page
   * @param site the site entity
   */
  public gotoLogin(site: any) {
    const userId = this.$route.params.id;
    this.$router.push({
      name: 'EC Site Login',
      params: { id: userId, siteId: site.id },
    });
  }

  public mounted() {

    let id = this.$route.params.id;
    if (this.$keycloak) {
      id = this.$keycloak.subject;
    }

    ApiService.getAllEcSites(id)
      .then((rsp) => {
        console.log(rsp);
        this.sites = rsp.data;
      })
      .catch((err) => {
        this.userLoadErrorMsg = ApiService.getErrorString(err);
      });
  }
}
