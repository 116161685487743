
























































import {Component, Vue} from 'vue-property-decorator';
import {uuid} from 'vue-uuid';
import ApiService from '../services/ApiService';
import EcSite from '../models/EcSite';

@Component({
  components: {},
})
export default class ECSiteLogin extends Vue {
  public userId = null;
  public siteId = null;

  public siteLoaded = false;
  public smsChecking = false;
  public site = {};
  public userLoadErrorMsg = null;
  public unexpectedError = null;

  public email = '';
  public password = '';
  public code = '';
  public uuid = '';

  public isDoingLogin = false;

  public codeType = null;
  public captcha = null;

  public step = '';
  public loginError = null;

  public codeMessageMap = {
    MFA:             Vue.prototype.trans('mfaPlease'),
    Verification:    Vue.prototype.trans('verifyPlease'),
    CAPTCHA:         Vue.prototype.trans('capchaPlease'),
    VerifyCodeLogin: Vue.prototype.trans('verifyCodeLoginPelase'),
  };

  /**
   * invoked by when mounted
   */
  public mounted() {
    this.userId = this.$route.params.id;
    if (this.$keycloak) {
      this.userId = this.$keycloak.subject;
    }

    this.siteId = this.$route.params.siteId;
    this.uuid = uuid.v4();

    ApiService.getECSite(this.userId, this.siteId)
      .then((rsp) => {
        this.site = rsp.data;
        this.loginInit();
      })
      .catch((err) => {
        this.siteLoaded = true;
        this.userLoadErrorMsg = ApiService.getErrorString(err);
      });
  }

  /**
   * login init
   */
  public loginInit() {
    ApiService.loginInit(this.userId, this.siteId, this.uuid)
      .then((rsp) => {
        console.log(rsp.data);
        this.step = rsp.data.authStep;
        this.email = rsp.data.emailId;
        this.codeType = rsp.data.codeType;
        this.captcha = rsp.data.image;
        if (rsp.data.reason) {
          this.loginError = rsp.data.reason;
        }
      })
      .catch((err) => {
        this.userLoadErrorMsg = ApiService.getErrorString(err);
      })
      .finally(() => {
        this.siteLoaded = true;
      });
  }

  /**
   * check email address
   * @param email
   */
  public validateEmail(email) {
    // tslint:disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  /**
   * is input invalid
   */
  public isInvalid() {
    // TODO: consider input validations for kojima and yahoo
    if (    this.site[String('ecSite')] === EcSite.kojima ||
            this.site[String('ecSite')] === EcSite.yahoo) {
      return false;
    }

    // ignore email and password in first step
    if (this.step !== 'FIRST' && this.password.trim().length <= 0) {
      return true;
    }
    if (this.step !== 'FIRST' && this.email.trim().length <= 0) {
      return true;
    }
    if (this.step !== 'FIRST' && !this.validateEmail(this.email) ) {
      return true;
    }

    return this.codeType && this.code.trim().length <= 0;
  }

  /**
   * when login clicked
   */
  public login() {
    const loginBody = {
      email: this.email,
      password: this.password,
      code: this.code.trim().length <= 0 ? null : this.code.trim(),
      uuid: this.uuid,
      siteId: this.siteId,
      step: this.step,
    };

    this.isDoingLogin = true;
    this.loginError = null;
    ApiService.login(this.userId, loginBody)
      .then((rsp) => {
        console.log(rsp);
        this.step = rsp.data.authStep;
        this.email = rsp.data.emailId;
        this.codeType = rsp.data.codeType;
        this.captcha = 'data:image/png;base64, ' + rsp.data.image;
        if (rsp.data.reason) {
          this.loginError = rsp.data.reason;
        }
        this.smsChecking = this.codeType === 'SMSApproval';

        if (this.step === 'ERROR') {
          this.unexpectedError = rsp.data.reason;
          this.$forceUpdate();
        } else if (this.step === 'DONE') {

          // login success
          this.$router.push({
            name: 'EC Site Settings',
            params: { id: this.userId },
          });
        }

        if (this.smsChecking) {
          this.code = 'SMS-Approval';
          this.$forceUpdate();
        }
      })
      .catch((err) => {
        this.loginError = ApiService.getErrorString(err);
      })
      .finally(() => {
        this.isDoingLogin = false;
      });
  }

  // /**
  //  * when login clicked
  //  */
  // public approved() {
  //   const loginBody = {
  //     email: this.email,
  //     password: this.password,
  //     code: this.code.trim().length <= 0 ? null : this.code.trim(),
  //     uuid: this.uuid,
  //     siteId: this.siteId,
  //   };

  //   ApiService.approved(this.userId, null)
  //     .then((rsp) => {
  //       console.log(rsp);
  //       this.step = rsp.data.authStep;

  //       if (this.step === 'SMS') {
  //         this.smsChecking = true;
  //         this.$forceUpdate();
  //       } else if (this.step === 'ERROR') {
  //         this.unexpectedError = rsp.data.reason;
  //         this.$forceUpdate();
  //       } else if (this.step === 'DONE') {

  //         // login success
  //         this.$router.push({
  //           name: 'EC Site Settings',
  //           params: { id: this.userId },
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //         this.unexpectedError = "Unexpected Error";
  //         this.$forceUpdate();
  //     });
  // }
}
